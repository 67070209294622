import * as React from "react";
import DemoButtons from "./DemoButtons";
import { Label } from "@fluentui/react";
import { Link } from "react-router-dom";

import Lightbox from "./Lightbox";
import HRfooter from "./HRFooter";
import Accordion from "./AccordionItem";

function Civic365() {




  const [isLightboxOpen, setLightboxOpen] = React.useState(false);
  const [selectedImageUrl, setSelectedImageUrl] = React.useState("");
  React.useEffect(() => {
    let currentMode = false;
    window.matchMedia('(prefers-color-scheme: dark)')?.addEventListener('change', event => {
      currentMode = event?.matches ? true : false;
    });
    var favicon = document.querySelector('link[rel="icon"]');
    favicon?.setAttribute("href", currentMode ? "https://ik.imagekit.io/zn4au2jftpm5/hr365/Icons/White%20Icons-96px/Civic365%2096px%20Trans%20White_pMnITvnhR.png?updatedAt=1707741529554" :"https://ik.imagekit.io/zn4au2jftpm5/hr365/Icons/Icons-96px/Civic365%2096px%20Trans_myLSK6DmS.png?updatedAt=1707741733280")  
},[])
  const openLightbox = (imageUrl) => {
    setSelectedImageUrl(imageUrl);
    setLightboxOpen(true);
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
  };
  const accordionItems = [
    {
      title: '1. How does Civic 365 improve citizen engagement?',
      content: `Civic 365 enhances citizen engagement through several key mechanisms:
        Accessibility: By offering mobile-friendly interfaces and self-service portals, Civic 365 makes it easier for citizens to interact with government services anytime, anywhere.
        Efficiency: Streamlined processes and automated workflows reduce response times, ensuring citizens receive timely assistance and feedback.
        Transparency: Civic 365 facilitates open communication by providing real-time updates on service requests and enabling citizens to track the progress of their inquiries.
        Empowerment: Through features like feedback mechanisms and community forums, Civic 365 empowers citizens to voice their opinions, contribute ideas, and actively participate in decision-making processes.
        Customization: Tailored service options and personalized experiences cater to the diverse needs and preferences of citizens, fostering a sense of inclusivity and belonging.
        Overall, Civic 365 transforms the citizen-government relationship by promoting accessibility, efficiency, transparency, empowerment, and customization, thereby enhancing citizen engagement and satisfaction.
        
        `
    },
    {
      title: '2.	How does Civic 365 improve citizen engagement? ',
      content: `Civic 365 enhances citizen engagement through several key mechanisms:
      Accessibility: By offering mobile-friendly interfaces and self-service portals, Civic 365 makes it     easier for citizens to interact with government services anytime, anywhere.
   Efficiency: Streamlined processes and automated workflows reduce response times, ensuring citizens receive timely assistance and feedback.
   Transparency: Civic 365 facilitates open communication by providing real-time updates on service requests and enabling citizens to track the progress of their inquiries.
   Empowerment: Through features like feedback mechanisms and community forums, Civic 365 empowers citizens to voice their opinions, contribute ideas, and actively participate in decision-making processes.
   Customization: Tailored service options and personalized experiences cater to the diverse needs and preferences of citizens, fostering a sense of inclusivity and belonging.
   Overall, Civic 365 transforms the citizen-government relationship by promoting accessibility, efficiency, transparency, empowerment, and customization, thereby enhancing citizen engagement and satisfaction.
   
      `
    },
    {
      title: `3.	Can Civic 365 be customized to suit our community's needs?`,
      content: `Yes, Civic 365 can be customized to align with the specific needs and requirements of your community. Here's how:
    Tailored Solutions: Civic 365 offers flexibility in its features and functionalities, allowing for customization to accommodate unique community needs. Whether it's the types of service requests, reporting metrics, or user interface preferences, the platform can be adapted to suit your community's specific requirements.
    Integration Capabilities: Civic 365 is designed to seamlessly integrate with existing government systems and applications. This integration enables customization by leveraging data from various sources to provide a comprehensive solution tailored to your community's needs.
    Configurable Settings: Administrators have control over various settings and configurations within Civic 365, allowing them to adjust parameters such as user permissions, workflow rules, and communication preferences to align with the community's preferences and operational processes.
    Feedback Mechanisms: Civic 365 includes feedback mechanisms that allow citizens to provide input on their experiences with the platform and government services. This feedback can inform ongoing customization efforts to ensure continuous improvement and alignment with community needs.
    Overall, Civic 365 offers extensive customization options to ensure that it effectively meets the unique needs and preferences of your community, providing a tailored solution for enhanced citizen engagement and service delivery.
    `
    }
  ];



  return (
    <>
      
      
      <div
        style={{
          position: "relative",
        }}
      >
        <div className="HR_MainHeaderSection HR_panel">
          <div className="HR_FlexCenterSpcBetween">
            <div className="HR_LogoSection">
              <a
                href="https://www.cubiclogics.com"
                target="_blank"
                rel="noreferrer"
                className="HR_logowithTExt"
              >
                <img
                  src={
                    "https://ik.imagekit.io/zn4au2jftpm5/hr365/LOGO/civic_shortColored%20(1)_eyKLS1cDu.png?updatedAt=1706079031529"
                  }
                  alt="Civic365"
                />
                <Label>Civic 365</Label>
              </a>

            </div> 
            <div className="HR_FlexCenterSpcBetween" style={{ width: "20%" }}>
            <div className="MB_button">
                <div className="HR_DemoButtonsSection headerbutton">
                <div style={{
                    width: "8vw"
                }} className="HR_PrimaryButton">
                <Link  to='/aboutus'>
                    <button>About us</button>
                </Link>
            </div>
                    <div className="HR_DemoButtons">
                        <div style={{
                            width: "10vw"
                        }} className="HR_PrimaryButton">
                            <a
                                href="https://request.civic365.in"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <button>Citizen's Login</button>
                            </a>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
          </div>
        </div>
      </div>
      <div className="HR_panel">
        <div className="HR_FlexCenterSpcBetween HR_ParentSection1">
          <div className="HR_Section1LeftSide">
            <h1 className="HR_primaryclr  ">
            Citizen Request Management Software - 311 CRM - Civic 365
            </h1>
            <p className="HR_textclr">
              Ready to unlock the advantages of enhanced citizen engagement and seamless service delivery? With its blend of digital integration and self-service options, Civic 365 empowers organizations to excel in customer service. Prioritizing cost-effectiveness and operational efficiency, it inspires peak performance among employees, fostering a culture of confidence and excellence in the workplace.
            </p>

                      <div className="HR_OuterDemoButtons">
                          <div className="desktopView">
                              <DemoButtons />
                          </div>
                          <div className="HR_DemoButtons MobileView">
                    <div className="HR_PrimaryButton">
                      <a
                        href="https://request.civic365.in"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <button>Citizen's Login</button>
                      </a>
                    </div>
                  </div>
                          
            </div>
          </div>
          <div className="HR_Section1RightSide">
            <img
              alt="MainImage"
              src={
                "https://ik.imagekit.io/zn4au2jftpm5/hr365/Civic365/01_jCrrpyeEk.jpg?updatedAt=1703939727503"
              }
              loading="lazy"
            />
          </div>
        </div>
      </div>
      <div className="HR_whitesection">
        <div className="HR_FlexCenterSpcBetween HR_ParentSection1 ">
          <div className="HR_Section1RightSide">
            <img
              alt="MainImage"
              src={
                "https://ik.imagekit.io/zn4au2jftpm5/hr365/Civic365/06_DJAQX-fu1.jpg?updatedAt=1703940177162"
              }
              loading="lazy"
              onClick={() =>
                openLightbox(
                  "https://ik.imagekit.io/zn4au2jftpm5/hr365/Civic365/06_DJAQX-fu1.jpg?updatedAt=1703940177162"
                )
              }
            />
            <Lightbox
              isOpen={isLightboxOpen}
              onClose={closeLightbox}
              imageUrl={selectedImageUrl}
            />
          </div>
          <div className="HR_Section1LeftSide1">
            <h2 className="HR_primaryclr">Community-Centric, Solution-Driven – Civic App</h2>
            <p className="HR_textclr">
              As governments around the globe place a premium on enhancing citizen interactions and public service experiences, citizens rightfully take center stage. Mirroring the benchmarks set by the private sector, there's a palpable shift in citizens' expectations, hastening the pace of digital transformation in engagement strategies.
              <br></br>To stay agile and attuned to evolving needs, public entities must pivot toward tailored digital services that align precisely with the citizen's viewpoint. Embracing this paradigm enables governments to forge deeper connections, streamline operations, and ultimately enrich the well-being of their communities. It's time to propel public offerings into the digital age, prioritizing citizen-centric innovation as the driving force.

            </p>
          </div>
        </div>
      </div>

      <div className="HR_panel">
        <div className="HR_FlexCenterSpcBetween HR_ParentSection1 ">
          <div className="HR_Section1LeftSide1">
            <h2 className="HR_primaryclr">
              Revolutionizing the Management of Citizen Customer Service Requests
            </h2>
            <p className="HR_textclr">
              Embark on a transformative journey in citizen customer service management with the Civic system. Our platform redefines the landscape by introducing a robust 311 CRM system at its foundation, guaranteeing smooth, effective, and user-friendly online solutions.
              Seamlessly intertwined with key local government tools, Civic 365 thrives on the infrastructure of Microsoft 365 and Azure, streamlining the management, analysis, and enhancement of citizen concerns. Say farewell to cumbersome processes and embrace effortless citizen engagement with Civic 365.

            </p>
          </div>
          <div className="HR_Section1RightSide">
            <img
              alt="MainImage"
              src={
                "https://ik.imagekit.io/zn4au2jftpm5/hr365/Civic365/05_dw7TuW5M-.jpg?updatedAt=1703940177015"
              }
              loading="lazy"
              onClick={() =>
                openLightbox(
                  "https://ik.imagekit.io/zn4au2jftpm5/hr365/Civic365/05_dw7TuW5M-.jpg?updatedAt=1703940177015"
                )
              }
            />
          </div>
        </div>
      </div>

      <div className="HR_whitesection">
        <div className="HR_FlexCenterSpcBetween HR_ParentSection1 ">
          <div className="HR_Section1RightSide">
            <img
              alt="MainImage"
              src={
                "https://ik.imagekit.io/zn4au2jftpm5/hr365/SPFxEDP/site-Helpdesk/setting_iUHtvT4Kw.png?updatedAt=1701273590120"
              }
              loading="lazy"
              onClick={() =>
                openLightbox(
                  "https://ik.imagekit.io/zn4au2jftpm5/hr365/SPFxEDP/site-Helpdesk/setting_iUHtvT4Kw.png?updatedAt=1701273590120"
                )
              }
            />
            <Lightbox
              isOpen={isLightboxOpen}
              onClose={closeLightbox}
              imageUrl={selectedImageUrl}
            />
          </div>
          <div className="HR_Section1LeftSide1">
            <h2 className="HR_primaryclr">Highlighted Features of the Civic System:</h2>
            <p className="HR_textclr">
              <ol className="removetick">
                <li style={{ listStyleType: 'disc' }}>
                  Swift Deployment: Easily introduce new service request types with intuitive point-and-click functions, ensuring rapid rollout without complications.
                </li>
                <li style={{ listStyleType: 'disc' }}>Comprehensive Knowledge Base: Access a vast and easily searchable repository, offering immediate access to a wealth of information for informed decision-making and efficient issue resolution.</li>
                <li style={{ listStyleType: 'disc' }}>All-in-One Citizen Portal: Equip citizens with a mobile-friendly, self-service hub for seamless interaction and service access anytime, anywhere, boosting convenience and contentment.</li>
                <li style={{ listStyleType: 'disc' }}>Integrated Call Center Solution: Seamlessly integrate with existing call center systems for a unified experience across all channels, optimizing communication and citizen support.</li>
                {/* <li  style={{ listStyleType: 'disc' }}>Advanced Geospatial Tools: Harness cutting-edge Esri Mapping and Geolocation integration for precise mapping and location services, ensuring accurate identification and resolution of citizen concerns.</li>
              <li  style={{ listStyleType: 'disc' }}>Dynamic Reporting Features: Utilize versatile reporting tools for valuable insights and data-driven decision-making, empowering your organization to continuously enhance service delivery and citizen satisfaction.</li> */}
              </ol>

            </p>
          </div>
        </div>
      </div>

      <div className="HR_panel">
        <div className="HR_FlexCenterSpcBetween HR_ParentSection1 ">
          <div className="HR_Section1LeftSide1">
            <h2 className="HR_primaryclr">
              Improving Efficiency in Citizen Customer Service:
            </h2>
            <p className="HR_textclr">
              <ol className="removetick">
                <li style={{ listStyleType: 'disc' }}>
                  Automated Request Assignment: Utilize automated routing to swiftly categorize and assign requests based on location and nature, ensuring efficient processing and resolution.
                </li>
                <li style={{ listStyleType: 'disc' }}>Prompt Escalation Procedures: Implement automatic escalation protocols according to predefined service level agreements, ensuring timely responses and adherence to established timelines for citizen satisfaction.</li>
                <li style={{ listStyleType: 'disc' }}>Duplicate Management Tools: Employ advanced duplicate detection mechanisms to proactively identify and prevent redundant submissions, streamlining workflows and eliminating unnecessary delays.</li>
                <li style={{ listStyleType: 'disc' }}>Interactive Communication Channels: Foster direct communication with residents through interactive features, facilitating prompt updates, clarifications, or follow-up inquiries for transparent and efficient resolution processes.</li>
              </ol>


            </p>
          </div>
          <div className="HR_Section1RightSide">
            <img
              alt="MainImage"
              src={
                "https://ik.imagekit.io/zn4au2jftpm5/hr365/Civic365/03_04jHsEy0k.jpg?updatedAt=1703939863705"
              }
              loading="lazy"
              onClick={() =>
                openLightbox(
                  "https://ik.imagekit.io/zn4au2jftpm5/hr365/Civic365/03_04jHsEy0k.jpg?updatedAt=1703939863705"
                )
              }
            />
          </div>
        </div>
      </div>


      <div className="HR_whitesection">
        <div className="HR_FlexCenterSpcBetween HR_ParentSection1 ">
          <div className="HR_Section1RightSide">
            <img
              alt="MainImage"
              src={
                "https://ik.imagekit.io/zn4au2jftpm5/hr365/Civic365/001_cXo353qNv.png?updatedAt=1703940879876"
              }
              loading="lazy"
              onClick={() =>
                openLightbox(
                  "https://ik.imagekit.io/zn4au2jftpm5/hr365/Civic365/001_cXo353qNv.png?updatedAt=1703940879876"
                )
              }
            />
            <Lightbox
              isOpen={isLightboxOpen}
              onClose={closeLightbox}
              imageUrl={selectedImageUrl}
            />
          </div>
          <div className="HR_Section1LeftSide1">
            <h2 className="HR_primaryclr"> Enabling Empowered Resident Management</h2>
            <p className="HR_textclr">Our resident management system software empowers residents to report non-emergency issues, share feedback, and request community information effortlessly. This innovative solution not only streamlines workflows for government staff but also fosters a collaborative and united community environment.
              <ol className="removetick">
                <li style={{ listStyleType: 'disc' }}>
                  Mobile Accessibility: Elevate resident convenience with seamless mobile integration, allowing them to report issues anytime, anywhere, reducing reliance on traditional phone calls and promoting efficient issue resolution on the go.
                </li>
                <li style={{ listStyleType: 'disc' }}>Increased Community Engagement: Promote active participation within the community by facilitating meaningful dialogues and interactions, empowering residents to actively shape their neighbourhoods and drive positive change.</li>


              </ol>

            </p>
          </div>
        </div>
      </div>

      <div className="HR_panel">
        <div className="HR_FlexCenterSpcBetween HR_ParentSection1 ">
          <div className="HR_Section1LeftSide1">
            <h2 className="HR_primaryclr">
              Be a Part of the Smart City Revolution with Civic365
            </h2>
            <p className="HR_textclr">
              At the heart of our offerings is an unyielding commitment to cloud security and privacy. Embracing a zero-trust policy, we ensure our clients maintain complete control over their application data within their cloud environment.
              As an esteemed member of Microsoft's Partner group and a certified Cloud Service Provider, Civic 365 leverages extensive experience within the Microsoft ecosystem to deliver unrivaled excellence in government solutions.
              With profound expertise in Microsoft 365 technologies and a deep understanding of the public sector, we are primed to deliver innovative solutions that cater to the evolving needs of tomorrow's forward-thinking cities.



            </p>
          </div>
          <div className="HR_Section1RightSide">
            <img
              alt="MainImage"
              src={
                "https://ik.imagekit.io/zn4au2jftpm5/hr365/Civic365/02_n5aF4U2yo.jpg?updatedAt=1703939727822"
              }
              loading="lazy"
              onClick={() =>
                openLightbox(
                  "https://ik.imagekit.io/zn4au2jftpm5/hr365/Civic365/02_n5aF4U2yo.jpg?updatedAt=1703939727822"
                )
              }
            />
          </div>
        </div>
      </div>


      <div className="HR_whitesection">
        <h1 className="HR_primaryclr">
          Are You Prepared to Enhance Your Citizen Journey?
        </h1>
        <p className="HR_textclr">
          Discover our array of government technology solutions now and reimagine the way your community engages with local government services.
        </p>
        <div className="HR_OuterDemoButtons HR_MainButton">
          <DemoButtons />
        </div>
      </div>


      <div className="HR_panel">
        <div className="HR_MT">
          <h1 className="HR_faq">Frequently Asked Questions</h1>

          <Accordion items={accordionItems}  />
        </div>
      </div>
      <HRfooter />
    </>
  );
}
export default Civic365;
